<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">
						
							<div class="d-flex align-items-center">
								<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
									Your profile
								</div>
								
							</div>
						</div>
			
						<div class="container h-100 py-4">

							<div class="row h-100 justify-content-center">

								<div class="col-12 col-md-10 col-lg-6">

									<div class="card mb-3">

										<div class="card-body">

											<p v-if="success" class="alert alert-success d-flex align-items-center">
												<span class="material-icons-outlined me-2">check_circle</span>
												Your details were updated successfully
											</p>

											<p v-if="error.message" class="alert alert-danger">
												{{ error.message }}
											</p>

											<form @submit.prevent="submit">

												<form_control
													label="Name"
													type="text"
													placeholder="eg: John Smith"
													name="name"
													:required="true"
													:error="error"
													v-model="name"
												/>

												<form_control
													label="Email address"
													type="email"
													placeholder="eg: john@areoi.io"
													name="email"
													:required="true"
													:error="error"
													v-model="email"
												/>

												<form_control
													label="Which best describes your role?"
													type="select"
													placeholder="Select role"
													name="job_role"
													:required="true"
													:error="error"
													:options="roles"
													v-model="job_role"
												/>

												<!-- <div class="p-3 pb-0 bg-light border rounded mb-3">

													<form_control
														label="Update your password"
														type="toggle"
														name="change_password"
														:required="true"
														:error="error"
														v-model="change_password"
													/>

													<form_control
														v-if="change_password"
														label="Current password"
														type="password"
														name="current_password"
														:required="true"
														:error="error"
														v-model="current_password"
													/>

													<form_control
														v-if="change_password"
														label="New password"
														type="password"
														name="password"
														:required="true"
														:error="error"
														v-model="password"
													/>

												</div> -->

												<btn_submit 
													label="Update your details" 
													icon="check"  
													:icon_after="true" 
													:loading="loading" 
												/>

											</form>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import support_link from '@/components/support_link'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import user_service from '@/services/user_service'

export default {
	name: 'profile',

	components: {
		layout,
		main_nav,
		support_link,
		form_control,
		btn_submit
	},

	data() {
		return {
			loading: false,
			name: null,
			email: null,
			password: null,
			job_role: null,
			current_password: null,
			change_password: null,
			error: {},
			success: null,
			roles: [
				{
					name: 'Select role',
					value: null
				},
				{
					name: 'Agency Owner',
					value: 'owner'
				},
				{
					name: 'Account Manager',
					value: 'account'
				},
				{
					name: 'Designer',
					value: 'designer'
				},
				{
					name: 'Developer',
					value: 'developer'
				},
				{
					name: 'Other',
					value: 'other'
				},
			]
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	mounted()
	{
		this.email = this.auth_user.email

		this.name = this.auth_user.name

		this.job_role = this.auth_user.job_role
	},

	methods: {
		reset_error()
		{
			this.error = {}

			this.success = null
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			user_service.update( this.auth_user.id, {
				name: this.name,
				email: this.email,
				password: this.password,
				job_role: this.job_role,
				current_password: this.current_password,
				change_password: this.change_password
			}).then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )

				this.password = null

				this.current_password = null

				this.change_password = null

				this.success = true
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>

<style>

</style>
